<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" class="elevation-0"></data-iterator>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card elevation="0" tile>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium">
            {{ modelObj._id ? 'Update Room' : 'Add Room' }}
          </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form ref="form">
            <v-container fluid class="pa-1">
              <v-row no-gutters class="pa-3 pb-0">
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-text-field outlined dense hide-details label="Block" v-model="modelObj.block" class="ma-0"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-text-field outlined dense hide-details label="Floor" v-model="modelObj.floor" class="ma-0"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-text-field outlined dense hide-details label="Room No" v-model="modelObj.roomNo" class="ma-0" :rules="setValidation('requiredValidation')"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-text-field outlined dense hide-details label="Hall No" v-model="modelObj.hallNo" class="ma-0"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-select :items="roomTypesList" item-text="name" item-value="value" outlined dense hide-details label="Room Type" v-model="modelObj.type" class="ma-0"></v-select>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-text-field type="number" outlined dense hide-details label="Max. Capacity" v-model="modelObj.maxCapacity" class="ma-0"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-checkbox outlined dense hide-details label="Is Active" v-model="modelObj.isactive" class="ma-0"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveUpdateHandler()"> {{ modelObj._id ? 'Update' : 'Save' }} </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset(); modelObj = {};" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      shiftTab: 0,
      listOfUserRoles: [],
      listOfEmployees: [],
      dialog: false,
      modelObj: {},
      payload: {
        showBackBtn: false,
        create: this.openDialog,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
   'data-iterator': () => import('@/components/DataIterator.vue'),
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Block',
        value: 'block'
      }, {
        text: 'Floor',
        value: 'floor'
      }, {
        text: 'Room No',
        value: 'roomNo'
      }, {
        text: 'Hall No',
        value: 'hallNo'
      }, {
        text: 'Max. Capacity',
        value: 'maxCapacity'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  mounted () {
    this.getListHandler()
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'infrastructure').then(({ data }) => {
        this.payload = { ...this.payload, items: data }
      })
    },
    openDialog () {
      this.modelObj = {
        isactive: true
      }
      this.dialog = true
    },
    saveUpdateHandler () {
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = this.modelObj._id ? `infrastructure/${this.modelObj._id}` : `/infrastructure`
        let method = this.modelObj._id ? 'put' : 'post'
        this.$_execute(method, url, this.modelObj)
          .then(() => {
            this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.modelObj = {}
            this.getListHandler()
          })
          .finally(() => { this.dialog = false })
      } else {
        this.$root.$emit('snackbar', { color: 'error', message: 'Please check the required fields!' })
      }
    },
    editHandler (id) {
      this.$_execute('get', `infrastructure/${id}`)
        .then(({ data }) => {
          this.modelObj = data
          this.dialog = true
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'infrastructure',
        _id: id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
